.selected-filters-title {
	color: #8c8ca1;
	text-align: right;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%; /* 20.8px */
	margin-right: 15px;
}

.selected-filters {
	text-align: -webkit-right;
	margin-left: 10px;
	padding: 0.25rem 0;
}
.selected-filters span {
	display: flex;
	padding: 5px 8px 5px 12px;
	width: max-content;
	height: 31px;
	justify-content: center;
	align-items: flex-start;
	gap: 5px;
	border-radius: 4px;
	border: 1.5px solid var(--main-colors-primary-clear-blue, #2673f0);
	color: var(--main-colors-primary-clear-blue, #2673f0);
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.56px;
	text-transform: uppercase;
}

.no-selected-filters {
	width: fit-content;
}
.no-selected-filters span {
	display: flex;
	padding: 5px 8px 5px 12px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 4px;
	border: 1.5px solid var(--utilitarian-neutrals-grey, #e4e4e8);
	color: var(--utilitarian-neutrals-light-slate, #8c8ca1);
	text-align: center;

	/* Button-text-small */
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.56px;
	text-transform: uppercase;
}

.selected-filters-icon {
	/* margin-left: 10px; */
	width: 19px;
	height: 19px;
	flex-shrink: 0;
	color: #2673f0;
}

.disabled-dropdown button {
	color: rgba(0, 0, 0, 0.26);
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0.12) !important;
	border: none !important;
	pointer-events: none;
}

/* Unassign Nodes */
.custom-modal-title {
	color: #0a1b3d;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%; /* 25.6px */
}

.custom-modal-body {
	color: #8c8ca1;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 170%; /* 22.1px */
}

.overlay-modal-no-technology {
	width: 557px;
	/* height: 93px; */
	border-radius: 4px;
	box-shadow: 0px 4px 14px 0px rgba(28, 28, 59, 0.25);
	background-color: #fdf1dc;
	border-left: 8px solid #f6b952;
}

.overlay-modal-no-technology-title {
	display: flex;
	width: 271px;
	height: 29px;
	/* flex-direction: column; */
	align-items: center;
	/* justify-content: center; */
	flex-shrink: 0;
	color: var(--utilitarian-yellow-60, #f6b952);
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%; /* 25.6px */
}

.overlay-modal-no-technology-text {
	/* width: 497.154px; */
	height: 27.133px;
	flex-shrink: 0;
	color: var(--utilitarian-neutrals-slate, #4a4a68);
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 170%; /* 22.1px */
	/* justify-content: center; */
}

.inventory_options_filter_button {
	text-transform: capitalize;
	border-radius: 4px;
	background: var(--main-colors-primary-clear-blue, #2673f0);
	width: 155px;
	height: 31px;
	display: flex;
	justify-content: center;
	box-shadow: none;
	margin-bottom: 0.5rem;
	padding: 5px, 8px, 5px, 12px;
	/* gap: 10px; */
	color: var(--utilitarian-gray-palette-0, #fff);
	text-align: center;
	/* Button-text-small */
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.56px;
	text-transform: uppercase;
	/* white-space: nowrap; */
}

.select_count_box {
	border: 1px solid #3077d3;
	border-radius: 4px;
	padding: 0.5rem 0.8rem 0.1rem 0.8rem;
	/* padding: 0.6rem; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.span_selection_count {
	color: #3077d3;
	font-weight: 500;
	font-size: 10px;
}
