.popover-content {
	background: #ecf1f4;
	box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.3);
	border-radius: 6px;
	font-family: "Poppins", sans-serif;
	color: #4a4a68;
	padding: 12px;
}

.pinRed {
	/* display: absolute; */
	width: 32px;
	height: 32px;
	border: 2px solid #b03333;
	/* border-radius: 50% 50% 50% 0; */
	background: #fa4d4d;
	margin: -46px 0 0 0px;
	transform: rotate(-45deg);
	border-radius: 50%;
	/* border: 2px solid white; */
	z-index: 10;
}

.pinGreen {
	/* display: absolute; */
	position: relative;
	width: 32px;
	height: 32px;
	border: 2px solid #5e9630;
	/* border-radius: 50% 50% 50% 0; */
	background: #9ce660;
	margin: -46px 0 0 0px;
	transform: rotate(-45deg);
	border-radius: 50%;
	/* border: 2px solid white; */
	z-index: 10;
}

.pinBlack {
	/* display: absolute; */
	width: 36px;
	height: 36px;
	border: 2px solid #000000;
	/* border-radius: 50% 50% 50% 0; */
	background: #ffffff;
	/* margin: -46px 0 0 0px; */
	border-radius: 50%;
	z-index: 0;
}

.pin {
	/* display: absolute; */
	width: 36px;
	height: 36px;
	border: 2px solid #ffffff;
	border-radius: 50% 50% 50% 0;
	background: #ffffff;
	margin: -46px 0 0 0px;
	transform: rotate(-45deg);
}

.pinImage {
	position: relative;
	width: 100%;
	height: 100%;
	transform: rotate(45deg);
	border-radius: 50%;
}

.letterDiv {
	position: relative;
	text-align: center;
	padding-top: 18%;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.letterText {
	font-weight: bolder;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1px;
}

.mapboxgl-control {
	background-color: #ffffff;
	padding: 10px;
	margin: 15px;
	border-radius: 5px;
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
		0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
		0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}

/*Occupation markers*/

/********************/

.occupationCircle {
	width: 28px;
	height: 28px;
	border-radius: 250px;
	/* font-size: 50px; */
	border-color: #000;
	line-height: 26px;
	text-align: center;
	color: #fff;
	font-weight: 700;
	border-style: solid;
	border-width: 2px;
}

/*Points of interest*/

/********************/

.poiMarkerDashboard {
	width: 36px;
	height: 36px;
	background-size: 34px;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	opacity: 0.9;
	display: flex;
	justify-content: center;
	align-items: center;
	background-origin: border-box;
	text-align: center;
	/* z-index: 1; */
}

#Thermostat {
	/* position: relative; */
	/* left: 18.3px;
   top: 3px; */
	left: 20px;
}

#Coffeemachine {
	/* left: 18.5px; */
	/* margin-left: 1px; */
}

#Elevator1 {
	margin-right: 1px;
	margin-bottom: 1px;
}

#Stairs1 {
	margin-bottom: 1px;
	margin-left: -1px;
}

/*Sensors*/

/********************/
.sensorPin {
	border-radius: 75% 65% 75% 5%;
	/* border-radius: 50% 50% 50% 5%; */
	border: 0px solid #4299ff;
	width: 33px;
	height: 33px;
	display: inline-block;
	color: white;
	background-color: #4299ff;
}

.sensorPin::after {
	content: "";
	width: 33px;
	height: 40px;
	border-radius: 50%;
}

/* Popups card com hover  */

/**************************/

/* #popoverDashboard {
  border: none;
  border-radius: 8px 8px 8px 8px !important;
  margin-bottom: 12px;
  left: -99px !important;
  border: solid;
  border-color: #fa4d4d;
} */

#popoverSensor {
	border: none;
	border-radius: 10px !important;
	left: -8px !important;
	margin-bottom: 22px;
	width: 400px !important;
	max-width: 400px !important;
}

.Dashboard {
	/* border-radius: 10px 10px 0px 0px !important; */
	border-radius: 8px 8px 8px 8px !important;
	cursor: pointer;
}

/* Popups card com hover  */
#popoverDashboard {
	border: none;
	border-radius: 8px 8px 8px 8px !important;
	margin-bottom: 12px;
	left: -5px !important;
	width: 220px;
}

.popoverCardDashboard {
	display: flex;
	flex-direction: column;
	text-align: left;
	letter-spacing: 0.3px;
	font-weight: 600;
	/* width: 200px !important;
    max-width: 200px; */
	/* border-radius: 8px 8px 8px 8px !important; */
	cursor: pointer;
	width: 225px;
}

.popup2Dashboard {
	/* width: 220px; */
	/* height: 200px; */
	/* border-radius: 8px 8px 8px 8px !important; */
	background-color: #ecf1f4;
	border-radius: 8px;
	box-shadow: 5px 9px 10px 4px rgba(0, 0, 0, 0.09);
}

.popupTextDashboard {
	/* margin-left: 1.1rem;
  margin-top: 0.8rem; */
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 5px;
	font-family: Roboto, sans-serif;
	color: #4a4a68;
}

.iconImageDashboard {
	width: 12px;
	filter: brightness(0) invert(1);
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	/* filter: brightness(0) invert(1); */
	/* position: relative; */
	/* left: 18.5px; */
	/* top: -15px;
    position: relative;
    right: 0.02px; */
	/* top: 3px; */
}

.popoverCardSensor {
	display: flex;
	flex-direction: column;
	text-align: left;
	letter-spacing: 0.3px;
	font-weight: 600;
	width: 400px;
	border-radius: 8px 8px 8px 8px !important;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
	/* remove mapbox logo */
	display: none;
}

.mapboxgl-ctrl-attrib {
	/* remove mapbox logo */
	display: none;
}

.mapboxgl-ctrl-logo {
	/* remove mapbox logo */
	display: none !important;
}

.carousel-control-prev-icon {
	width: 9px !important;
	opacity: 1;
	color: #ebeef7;
}

.carousel-control-next-icon {
	width: 9px !important;
	opacity: 1;
	color: #ebeef7;
}

/* Zone markers css  */
.zoneMarkersDashboard {
	border-radius: 50%;
	border: 2px solid white;
	/* opacity: 0.6 !important; */
	/* opacity: 0.8 !important; */
	background-color: transparent;
	/* background-color: #2673f0; */
	width: 24px;
	height: 24px;
	z-index: 0;
}

.poiMarkersDashboard {
	border-radius: 50%;
	/* border: 2px solid white; */
	outline: 2px solid #ffffff;
	/* opacity: 0.6 !important; */
	background-color: #5491f3;
	width: 26px;
	height: 26px;
	z-index: 0;
}

.zoneMarkersDashboardBookable {
	border-radius: 50%;
	border: 2px solid white;
	opacity: 0.8 !important;
	background-color: #ffba57;
	width: 32px;
	height: 32px;
	z-index: 0;
}

.markerZoneDivDashboard {
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	height: 27px;
	color: white;
}

.markerZoneDivDashboardSmall {
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	height: 18px;
	color: white;
}

.spider-leg-container .spider-leg-pin {
	position: relative;
	width: 36px;
	height: 36px;
	margin-left: -16px;
	margin-top: -13px;
	background-position: -110px -110px;
}

.spider-leg-container .spider-leg-line {
	width: 10px;
	background-color: #d6d6d6;
}

.spider-leg-container:hover .spider-leg-line {
	background-color: #b4b4b4;
}

/* CSS for spiderifier */
.spider-leg-container {
	width: 1px;
	height: 1px;
	overflow: display;
	will-change: transform;
}

.spider-leg-container:hover {
	cursor: pointer;
}

.spider-leg-container .spider-leg-pin {
	position: relative;
	z-index: 1;
}

.spider-leg-container .spider-leg-pin.default-spider-pin {
	position: relative;
	width: 25px;
	height: 41px;
	margin-left: -11.5px;
	margin-top: -38.5px;
	background-image: url(https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0-beta.2/images/marker-icon.png);
}

.spider-leg-container .spider-leg-line {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 2px;
	background-color: #343434;
	opacity: 0.45;
	transform-origin: bottom;
	z-index: 0;
	height: 0;
}

.spider-leg-container:hover .spider-leg-line {
	opacity: 1;
}

/* Animations specific styles */

.spider-leg-container.animate {
	-webkit-transition: margin 0.15s linear;
	-moz-transition: margin 0.15s linear;
	-ms-transition: margin 0.15s linear;
	-o-transition: margin 0.15s linear;
	transition: margin 0.15s linear;
}

.spider-leg-container.initial,
.spider-leg-container.exit {
	margin-left: 0 !important;
	margin-top: 0 !important;
	height: 0;
}

.spider-leg-container.animate .spider-leg-line {
	-webkit-transition: all 0.15s linear;
	-moz-transition: all 0.15s linear;
	-ms-transition: all 0.15s linear;
	-o-transition: all 0.15s linear;
	transition: all 0.15s linear;

	-webkit-transition-delay: inherit;
	-moz-transition-delay: inherit;
	-ms-transition-delay: inherit;
	-o-transition-delay: inherit;
	transition-delay: inherit;
}

.spider-leg-container.animate.initial .spider-leg-line,
.spider-leg-container.animate.exit .spider-leg-line {
	height: 0 !important;
}

/* Connectors */

/********************/

.popupLineRoutes_dashboard {
	border: 2px solid white;
	width: auto;
	min-width: 28px;
	height: 28px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #8680ff;
}

/* .connectorsMarkerDashboard {
  width: 36px;
  height: 36px;
  background-size: 34px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-origin: border-box;
  text-align: center;
} */

.MuiTooltip-tooltipPlacementBottom {
	width: 6rem !important;
	font-size: 8px !important;
	text-align: center !important;
}

.tabsDiv {
	margin: -1rem;
	margin-top: 1px;
}

.tabDashboard {
	/* border: 3px solid red !important; */
	min-width: 55px !important;
}

.PrivateTabIndicator-colorPrimary-13 {
	display: none !important;
}

.MuiTab-textColorPrimary.Mui-selected {
	color: #dc4d79 !important;
}

#ELEVATOR_TAB {
	/* background-color: red !important; */
}

.locationMarker {
	background-color: #0c48aa;
	/* background-color: #323389; */
	/* background-color: transparent; */
	background-size: 50px;
	background-repeat: no-repeat;
	/* width: 36px; */
	/* height: 36px; */
	border-radius: 50%;
	/* padding-bottom: 20px; */
	/* border: 3px solid white; */
	display: flex;
	justify-content: center;
	align-items: center;
	background-origin: border-box;
	text-align: center;
}

.locationMarker:hover {
	background-color: #8680ff;
	transition: ease-out 0.1s;
}

.popupNoLocation {
	text-align: center;
	padding: 1rem 1rem 0rem 1rem;
}

.photoIconNoLocation:hover {
	color: #323389;
	transition: ease-out 0.1s;
}

.carousel-inner {
	border-radius: 8px 8px 0px 0px;
	overflow: hidden;
}

.EmpVisNoLocationDiv {
	display: flex;
	flex-direction: row;
	/* border: 1px solid red; */
	margin-bottom: 0.5rem;
}

.guestsNoLocationDiv {
	display: flex;
	flex-direction: row;
	/* border: 1px solid red; */
	margin-bottom: 0.5rem;
}

.popup_title_dashboard {
	color: #4a4a68;
	font-family: Poppins;
	font-size: 13px;
	font-weight: 700;
	padding: 9px 10px;
}

.spider-leg-line {
	background-color: #fff !important;
}

.warning_tag {
	border-radius: 50%;
	box-shadow: 0px 0px 1px 1px #0000001a;
}

.warning_pulse {
	animation: pulse-animation-warning 1.3s infinite;
	border-radius: 50%;
}

.popover .arrow {
	display: none !important;
}

.popover .arrow::before,
.popover .arrow::after {
	display: none;
}

@keyframes pulse-animation-warning {
	0% {
		box-shadow: 0 0 0 0px rgb(228, 162, 47);
	}
	100% {
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}
}

.critical_pulse {
	animation: pulse-animation-critical 1.3s infinite;
	border-radius: 50%;
}

@keyframes pulse-animation-critical {
	0% {
		box-shadow: 0 0 0 0px rgba(230, 80, 25, 0.8);
	}
	100% {
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}
}

/* @keyframes pulse-animation-critical {
	0% {
		box-shadow: 0 0 0 10px rgba(230, 80, 25, 0.8);
	}
	100% {
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0), 0 0 0 10px rgba(251, 230, 222, 1);
	}
} */
