.div-3d {
	height: 31px;
	width: 31px !important;
	border-radius: 5px;
}

.button-3d {
	height: 31px;
	width: 31px;
	border-radius: 5px;
	background-color: #dde9fd;
	color: #2673f0;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.button-3d:hover {
	background-color: #6ca4ff;
	color: #ffffff;
}

.button-3d:active {
	background-color: #2673f0;
}
