/* ━━━━━━━━━━━ Drawer options ━━━━━━━━━━━ */
.container_drawer_bar_content {
	display: flex;
	/* border: 3px solid green; */
	position: relative;
}

.wrapper_drawer_bar_content {
	background-color: white;
	border-radius: 5px;
	box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
	width: 50px;
	/* height: 65px; */
	display: flex;
	flex-direction: column;
	/* justify-content: center;
    align-items: center; */
}

.drawer_icon {
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.expand_button_drawer {
	height: 20px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: ease-in-out 0.2s;
}

.expand_button_drawer:hover {
	background-color: #bdc3c7;
	border-radius: 0 0 5px 5px;
}

.expanded_drawer {
	/* background: gold; */
	/* visibility: visible; */
	background-color: white;
	/* display: block; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	opacity: 1;
	height: 500px;
	max-height: 500px;
	transition: visibility 0s, opacity 0.3s, max-height 0.5s linear;
	/* animation: expandAnimation 0.9s ease forwards; */
}

.collapsed_drawer {
	/* visibility: hidden; */
	/* max-height: 0px; */
	display: none;
	opacity: 0;
	transition: max-height 0.3s, opacity 0.3s, visibility 0.3s linear;
	/* animation: collapseAnimation 0.4s ease-in forwards; */
}

/* @keyframes expandAnimation {
    from {
      height: 0px;
    }
    to {
      height: 500px;
    }
  } */

/* @keyframes collapseAnimation {
    from {
      height: 500px;
    }
    to {
      height: 0px;
    }
  } */

/* ━━━━━━━━━━━━━━━━━━━ Search wrapper ━━━━━━━━━━━━━━━━━━━━━━  */
.search_wrapper {
	/* border: 2px solid red; */
	position: fixed;
	right: 4.5rem;
	/* height: auto; */
	/* 
     width: 178px;
     height: 200px;
     background: #FFFFFF;
     box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
     border-radius: 5px; */
	/* border: 1px solid red; */
	/* margin-right: 0.7rem; */
}

.wrapper_new_search_bar {
	background: #ffffff;
	box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	/* padding: 6px 5px; */
}

.container_new_search_bar {
	text-align: center;
	padding-left: 0px;
	border-radius: 5px;
	position: relative;
	display: flex;
}

.dropdowns_new_wrapper {
	display: flex;
}

/* ━━━━━━━━━━━━━━━━━━━ Filter wrapper ━━━━━━━━━━━━━━━━━━━━━━  */
.filter_info_wrapper {
	/* border: 2px solid red; */
	width: 349px;
	padding: 10px;
	/* overflow-y: scroll; */
	/* height: 424px; */
	height: auto;
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	/* margin-right: 0.7rem; */
	position: fixed;
	right: 4.5rem;

	display: flex;
	flex-direction: column;
}

.drawer_option_filter_options {
	text-align: center;
	overflow-y: auto;
	cursor: s-resize;
}

.drawer_option_filter_options > *:first-child {
	position: relative;
	top: 0;
	left: 0;
	/* width: 100%; */
}

.drawer_option_filter_options::-webkit-scrollbar {
	/* WebKit */
	width: 0;
	height: 0;
}

.drawer_option_title_icon1 {
	/* margin-left: 1rem; */
	margin-right: 0.5rem;
	margin-top: -0.4rem;
	font-size: 16px;
}

.drawer_option_title_icon {
	margin-left: 1rem;
	margin-right: 0.5rem;
	margin-top: -0.4rem;
	font-size: 16px;
}

.drawer_option_title_span {
	font-weight: 500;
	font-size: 12px;
	font-family: "Poppins";
}

.drawer_option_title_secondary {
	margin-top: 1rem;
	margin-left: 0.2rem;
	font-weight: 500;
	font-size: 11px;
	font-family: "Poppins";
	margin-bottom: 0.4rem;
}

.drawer_options_filter_button {
	text-transform: capitalize !important;
	border-radius: 5px !important;
	font-size: 11px !important;
	font-weight: 500 !important;
	font-family: "Poppins" !important;
	width: 160px;
	height: 32px;
	display: flex;
	justify-content: space-between !important;
	box-shadow: none !important;
	margin-bottom: 0.5rem !important;
	/* white-space: nowrap; */
}

/* .drawer_options_filter_button:hover {
    background-color: #015aa5 !important;
  } */

.drawer_options_filter_button_select_all_filter_one {
	width: auto;
	height: 32px;
	margin-top: 0.6rem !important;
	font-size: 12px !important;
	font-family: "Poppins" !important;
	box-shadow: none !important;
	letter-spacing: 0.04em !important;
}

.drawer_options_filter_button_select_all {
	width: 156px;
	height: 32px;
	margin-bottom: 0.6rem !important;
	font-size: 12px !important;
	font-family: "Poppins" !important;
	box-shadow: none !important;
	letter-spacing: 0.04em !important;
}

.drawer_options_filter_button_select_all:hover {
	background-color: #c5d9fb !important;
}

/* ━━━━━━━━━━━━━━━━━━━ Path wrapper ━━━━━━━━━━━━━━━━━━━━━━  */
.path_drawer_wrapper {
	/* border: 2px solid red; */
	/* margin-right: 0.7rem; */
	position: fixed;
	right: 4.5rem;
}

.wrapper_new_path_container {
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	width: 320px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.path_drawer_tabs_div {
	/* border: 1px solid red; */
	margin-top: 2rem;
	display: flex;
	justify-content: center;
}

.path_drawer_inputs_container {
	/* border: 1px solid red;  */
	margin: 1.2rem 1rem 0.3rem 1rem;
}

.path_drawer_inputs_points {
	/* border: 2px solid green;  */
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	margin: 0rem 0rem 1rem 0;
	position: relative;
}

.path_drawer_input_autocomplete {
	/* border: 1px solid purple; */
	width: 100%;
	/* ↓ This puts the dropdown on top of the input */
	/* display: flex; */
	/* ↓ opens dropdown upward */
	/* align-items: flex-start; */
}

.path_drawer_inputs_icon_starting {
	/* border: 1px solid red; */
	margin-right: 9px;
}

.path_drawer_inputs_icon_ending {
	/* border: 1px solid red; */
	margin-right: 8px;
	padding-right: 2px;
	padding-left: 2px;
}

/* Dots  */
.path_drawer_dot_1 {
	position: fixed;
	top: 23.9%;
	right: 26.6rem;
	border-radius: 50%;
	background-color: #2c3e50;
	width: 3px;
	height: 3px;
	margin: 8px;
}

.path_drawer_dot_2 {
	position: fixed;
	top: 25.9%;
	right: 26.6rem;
	border-radius: 50%;
	background-color: #2c3e50;
	width: 3px;
	height: 3px;
	margin: 8px;
}

.path_drawer_dot_3 {
	position: fixed;
	top: 27.9%;
	right: 26.6rem;
	border-radius: 50%;
	background-color: #2c3e50;
	width: 3px;
	height: 3px;
	margin: 8px;
}

/* ━━━━━━━━━━━━━━━━━━━ Occupancy Filter wrapper ━━━━━━━━━━━━━━━━━━━━━━  */
.occupancy_filter_wrapper {
	/* border: 2px solid red; */
	width: 178px;
	/* height: 394px; */
	height: auto;
	/* height: 424px; */
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	/* margin-right: 0.7rem; */
	position: fixed;
	right: 4.5rem;
}

.drawer_option_title_third {
	margin-top: 1rem;
	margin-left: 1rem;
	font-weight: 500;
	font-size: 11px;
	font-family: "Poppins";
	margin-bottom: 0.4rem;
}

/* options of the roles in the filter */
.drawer_options_roles_buttons {
	text-align: center;
	max-height: 5.9rem;
	overflow-y: auto;
	/* cursor: s-resize; */
}

/* .drawer_options_roles_buttons > *:first-child {
	position: relative;
	top: 0;
	left: 0;
} */

/* .drawer_options_roles_buttons::-webkit-scrollbar {
	width: 0;
	height: 0;
} */

#scroll-container-drawer-roles-buttons {
	position: relative;
	width: auto;
	height: auto;
}

#scroll-container-drawer-assets-buttons {
	position: relative;
	width: auto;
	height: auto;
}

/* ━━━━━━━━━━━━━━━━━━━ Heatmap filter wrapper ━━━━━━━━━━━━━━━━━━━━━━  */

.heatmap_filter_wrapper {
	/* border: 2px solid red; */
	/* width: 349px; */
	width: auto;
	padding: 10px;
	/* overflow-y: scroll; */
	/* height: 424px; */
	height: auto;
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	/* margin-right: 0.7rem; */
	position: fixed;
	right: 4.5rem;

	display: flex;
	flex-direction: column;
}

/* ━━━━━━━━━━━━━━━━━━━ Layers view wrapper ━━━━━━━━━━━━━━━━━━━━━━  */

.layers_view_wrapper {
	/* border: 2px solid red; */
	width: 332px;
	height: 145px;
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	position: fixed;
	right: 4.5rem;
	top: 32.3rem;
}

.layers_view_container_box {
	display: flex;
	justify-content: space-evenly;
	margin-top: 0.7rem;
}

.layers_view_box_active {
	width: 5.3rem;
	height: 5.3rem;
	border-radius: 2px;
	padding: 2px;
	background-color: #ffffff;
	border: 2px solid #2673f0;
}

.layers_view_box {
	width: 5.3rem;
	height: 5.3rem;
	border-radius: 2px;
	padding: 2px;
	background-color: #ffffff;
	/* border: 2px solid #2673f0; */
}

.layers_view_legend {
	margin: 4px;
	font-family: "Poppins";
	font-weight: 500;
	font-size: 11px;
	text-align: center;
}

.layers_view_images {
	width: 100%;
	height: 100%;
	border-radius: 2px;
}

.layers_view_box:hover {
	background-color: #d3d3d3;
	cursor: pointer;
}

@media only screen and (max-width: 1198px) {
	.path_drawer_dot_1 {
		/* background-color: red; */
		right: 28.6rem;
		top: 17.7%;
	}

	.path_drawer_dot_2 {
		right: 28.6rem;
		top: 19.7%;
	}

	.path_drawer_dot_3 {
		right: 28.6rem;
		top: 21.7%;
	}
}

/* ━━━━━━━━━━━━━━━━━━━ Resize Markers ━━━━━━━━━━━━━━━━━━━━━━  */
.resize_info_wrapper {
	width: 250px;
	padding: 10px;
	height: auto;
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	position: fixed;
	right: 4.5rem;
	display: flex;
	flex-direction: column;
}

.controller-title {
	/* color: #8c8ca1; */
	font-family: "Poppins";
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 4px 0;
	color: #4a4a68;
}

.onHover:hover {
	/* background-color: pink; */
	outline: 1px solid #3077d3;
}
